import React from 'react';
import { withRouter } from 'react-router-dom';
import { ClientRouter } from '@shopify/app-bridge-react';

function MyRouter(props) {
  const {history} = props;
  return <ClientRouter history={history} />;
};

export default withRouter(MyRouter);
