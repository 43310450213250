import React, { useEffect, useState } from 'react'
import { Button, Card, DataTable, Layout, TextField } from '@shopify/polaris'

function CurrentOrder({ order }) {
  const [form, setForm] = useState(order.line_items.reduce((acc, item) => ({ ...acc, [item.id]: item.price }), {}));
  const [editing, setEditing] = useState(order.line_items.reduce((acc, item) => ({ ...acc, [item.id]: false }), {}));
  const [changes, setChanges] = useState([]);

  useEffect(() => {console.log(order)}, [order]);

  const headers = [
    { type: 'text', label: 'Item', fn: (x) => x.title },
    { type: 'text', label: 'Unit', fn: (x) => editing[x.id] ? <TextField type="text" value={form[x.id]} onChange={(val) => setForm({ ...form, [x.id]: { ...form[x.id], title: val } })}/> : x.variant_title },
    { type: 'text', label: 'Quantity', fn: (x) => x.fulfillable_quantity },
    { type: 'text', label: 'Price', fn: (x) => x.price },
    { type: 'text', label: 'Actual Price', fn: (x) =>
      editing[x.id] ? <TextField type="text" value={form[x.id]} onChange={(val) => setForm({ ...form, [x.id]: { ...form[x.id], actual_price: val } })}/> : <Button onClick={() => setEditing({ ...editing, [x.id]: !editing[x.id] })}>Edit</Button>
    }
  ];

  const items = order.line_items.filter(item => item.fulfillable_quantity > 0 && item.title.indexOf('Refundable') == -1);

  return (
    <Layout.Section>
      <Card>
        <DataTable
          columnContentTypes={headers.map(h => h.type)}
          headings={headers.map(h => h.label)}
          rows={items.map(order => headers.map(header => header.fn(header.col ? order[header.col] : order)))}
        />
      </Card>
    </Layout.Section>
  )
}

export default CurrentOrder
