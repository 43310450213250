// Orders
export const orderCustomerName = (order) => order.customer ? `${order.customer.first_name || ''} ${order.customer.last_name}` : '';
export const orderTitle = (order) => `Order ${order.name} for ${orderCustomerName(order)}`

export const getWeekday = (weekday) => {
  const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  return weekdays[weekday];
}

export const getMonth = (index) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  return months[index];
}

export const orderShippingMethod = (order) => {
  const METHOD_KEY = 'Method';
  const methodNote = order.note_attributes.filter(x => x.name === METHOD_KEY)[0];
  if (methodNote) {
    return methodNote.value;
  }

  return order.service ? order.service.type : 'manual';
}

export const parseOrderData = (order) => {
  const DATE_KEY = 'Date';
  const PICKUP_TIME_KEY = 'Pickup Time';

  // For special pickup times
  let pickupTime = order.note_attributes.filter(x => x.name === PICKUP_TIME_KEY)[0];
  pickupTime = pickupTime ? pickupTime.value : null;

  // Get data from service and fallback to note attributes
  const date = order.service.date || order.note_attributes.filter(x => x.name === DATE_KEY)[0];
  if (!date) {
    return { collectionDate: null, date: null, year: null, month: null, day: null, hour: null, pickupTime };
  }
  let year, month, day;
  [year, month, day] = date.split('-');
  const hour = order.service.hour;

  let collectionDate = new Date(year, month - 1, day)
  collectionDate.setHours(hour);

  return { collectionDate, date, year, month, day, hour, pickupTime };
}

export const noteKeys = {
  pickupLocation: 'Pickup Location'
}

export const orderNote = (order, name) => {
  const note = order.note_attributes.filter(n => n.name.toLowerCase() === name.toLowerCase())[0];
  return note ? note.value : null;
}
