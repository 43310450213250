import React from 'react'
import PropTypes from "prop-types"

function Checkout() {
  return (
    <div>
      <h1>Checkout</h1>
    </div>
  )
}

export default Checkout

