import React, { useState } from 'react';
import {
  Layout,
  Page,
  FooterHelp,
  Card,
  Link,
} from '@shopify/polaris';
import ShutdownDatePicker from './ShutdownDatePicker';

export default function DeliveryShutdown() {
  const [shutdownDates, setShutdownDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const onPick = ({ start, end }) => {
    console.log({ start, end });
    if (start > shutdownDates.end) {
      setShutdownDates({ start: shutdownDates.start, end: start });
    } else {
      setShutdownDates({ start, end });
    }
  }

  return (
    <Page title="Grocer Box">
      <Layout>
        <Layout.AnnotatedSection
          title="Delivery Shutdown Days"
          description="Disable deliveries on specific days"
        >
          <Card sectioned>
            <Card.Section>
              <ShutdownDatePicker
                selectedDates={shutdownDates}
                setSelectedDates={onPick}
              />
            </Card.Section>
            <Card.Section>
              {/* <Calendar
                className="Polaris-DatePicker"
                tileClassName={(d) => 'Polaris-DatePicker__Day'}
              /> */}
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.Section>
          <FooterHelp>
            For more details on Grocer Box, visit <Link url="https://getgrocerbox.com">getgrocerbox.com</Link>.
          </FooterHelp>
        </Layout.Section>
      </Layout>
    </Page>
  )
}
