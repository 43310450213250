import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react';
import { AppProvider } from '@shopify/polaris';

import { AdminProvider } from './admin';
import Routes from './Routes';
import RoutePropagator from './RoutePropagator';
import MyRouter from './MyRouter';
import AppContainer from './AppContainer';

export default function App({ shop, apiKey, webhooks, carriers }) {
  return (
    <AppProvider>
      <AdminProvider shop={shop} webhooks={webhooks} carriers={carriers}>
        <BrowserRouter>
          <AppBridgeProvider
            config={{
              apiKey,
              shopOrigin: shop.shopify_domain,
            }}
          >
            <RoutePropagator/>
            <MyRouter />

            <AppContainer>
              <Routes />
            </AppContainer>

            </AppBridgeProvider>
        </BrowserRouter>
      </AdminProvider>
    </AppProvider>
  );
}
