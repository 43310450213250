import React, {useState, useCallback} from 'react';
import { DatePicker } from "@shopify/polaris";

export default function ShutdownDatePicker({ selectedDates, setSelectedDates }) {
  const [{month, year}, setDate] = useState({
    month: 1,
    year: 2018,
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({month, year}),
    [],
  );

  return (
    <DatePicker
      month={month}
      year={year}
      onChange={setSelectedDates}
      onMonthChange={handleMonthChange}
      selected={selectedDates}
    />
  );
}