import React, { useState } from 'react'

import { Page, Layout, FormLayout, TextField } from '@shopify/polaris'
import { useAdmin } from '../admin';
import WeeklySchedule from './WeeklySchedule';
import CalendarSettings from './CalendarSettings';
import DeliverySettings from './DeliverySettings';

export default function Settings() {
  const { shop, saveCalendar, saveSchedule, updateShopConfig, saveDeliveryRate, deleteDeliveryRate } = useAdmin();
  const [form, setForm] = useState({ delivery_notes: shop.shop_config.delivery_notes });

  const handleSave = () => {
    updateShopConfig(form);
  }

  return (
    <Page title="Settings">
      <Layout>
        <DeliverySettings shop={shop} saveDeliveryRate={saveDeliveryRate} deleteDeliveryRate={deleteDeliveryRate} />

        <Layout.AnnotatedSection
          title="Delivery Settings"
          description="Configure delivery settings."
        >
          <FormLayout>
            <TextField
              label="Delivery Notes"
              value={form ? form.delivery_notes : ''}
              onChange={(val) => setForm({ delivery_notes: val })}
              onBlur={() => handleSave()}
              multiline
              helpText="Add instructions for delivery drivers."
            />
          </FormLayout>
        </Layout.AnnotatedSection>

        <WeeklySchedule schedule={shop.shop_config.schedule} handleSave={saveSchedule} />

        <CalendarSettings calendar={shop.shop_config.calendar} handleSave={saveCalendar} />
      </Layout>
    </Page>
  )
}
