import React, { useState, useCallback } from 'react';
import { AppProvider, Frame, Layout, Form, FormLayout, TextField, Button, Card, Page, Banner } from '@shopify/polaris';

export default function PasswordResetRequest({ url }) {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = useCallback((_event) => {
    _event.preventDefault();
    fetch(url,
        {
          method: 'POST',
          body: JSON.stringify({ password: { email } }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(res => res.json())
      .then(res => {
        setEmail('');
        setSuccess(true);
      })
      .catch(err => {
        setError(true);
      })
  }, [email]);

  const reset = () => {
    setSuccess(false);
    setEmail('');
    setError(false);
  }

  const handleEmailChange = useCallback((value) => setEmail(value), []);

  return (
    <AppProvider>
      <Frame>
        <Page title={success ? 'Success! Check your email.' : `Reset Password`}>
          <Layout>
            <Layout.Section>
              {error ?
                <Banner status="warning">
                  There was an error sending your password reset email. Ensure your email is spelled correctly and try again.<br/>If the problem continues email <a href="mailto:support@grocerbox.com">support@grocerbox.com</a> for assistance.
                </Banner>
              : null}

              <Card sectioned>
                {success ?
                  <p>You will receive an email within the next few minutes with instructions for changing your password. You may close this window.</p>
                : null}

                {!success ?
                  <Form onSubmit={handleSubmit}>
                    <FormLayout>
                      <TextField
                        value={email}
                        onChange={handleEmailChange}
                        label="To be emailed a link to reset your password, please enter your email address below."
                        type="email"
                      />

                      <Button primary submit>Submit</Button>
                    </FormLayout>
                  </Form>
                : null}
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </Frame>
    </AppProvider>
  );
}
