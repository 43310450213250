import React, { useState, useCallback } from 'react';
import { AppProvider, Frame, Layout, Form, FormLayout, TextField, Button, Card, Page, Banner } from '@shopify/polaris';

export default function PasswordResetForm({ url }) {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = useCallback((_event) => {
    _event.preventDefault();
    fetch(url,
        {
          method: 'PUT',
          body: JSON.stringify({ password_reset: { password } }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(res => res.json())
      .then(res => {
        setPassword('');
        setPasswordConfirmation('');
        setSuccess(true);
      })
      .catch(err => {
        console.log({ err });
        setError(true);
      })
  }, [password]);

  const handlePasswordChange = useCallback((value) => setPassword(value), []);
  const handlePasswordConfirmationChange = useCallback((value) => setPasswordConfirmation(value), []);

  return (
    <AppProvider>
      <Frame>
        <Page title={success ? `Success!` : `Reset Password`}>
          <Layout>
            <Layout.Section>
              {error ?
                <Banner status="warning">
                  There was an error updating your password, please try again.<br/>If the problem continues email <a href="mailto:support@grocerbox.com">support@grocerbox.com</a> for assistance.
                </Banner>
              : null}

              <Card sectioned>
                {success ?
                  <p>Password successfully reset! You may close this window.</p>
                : null}

                {!success ?
                  <Form onSubmit={handleSubmit}>
                    <FormLayout>
                      <TextField
                        value={password}
                        onChange={handlePasswordChange}
                        label="New Password"
                        type="password"
                      />

                      <TextField
                        value={passwordConfirmation}
                        onChange={handlePasswordConfirmationChange}
                        label="Re-enter Password"
                        type="password"
                      />

                      <Button submit primary disabled={password !== passwordConfirmation}>Submit</Button>
                    </FormLayout>
                  </Form>
                : null}
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </Frame>
    </AppProvider>
  );
}
