import React, { useEffect, useState } from 'react';
import { AppProvider, Badge, Button, Card, DataTable, Layout, Page, SkeletonBodyText, SkeletonDisplayText, Spinner, Tabs, TextContainer } from '@shopify/polaris';
import OrderCollectionDate from './OrderCollectionDate';
import { getWeekday } from '../../../util/helpers';
import { fetchData } from '../../../util/fetch';
import { useAdmin } from '../admin';

export default function Orders() {
  const { shop, orders, setOrders } = useAdmin();
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState({});
  const [tabs, setTabs] = useState([]);

  // Generate tabs using the following 7 days
  useEffect(() => {
    const today = new Date();
    const week = [];
    const dayLabels = ['today', 'tomorrow'];

    for (let i = 0; i < 7; i++) {
      const d = new Date();
      d.setDate(today.getDate() + i);
      week.push(d);
    }

    const newTabs = week.map((date, index) => {
      const label = dayLabels[index] || getWeekday(date.getDay());

      return {
        id: label,
        date: date.toLocaleDateString('en-ca'),
        content: label,
        title: `Order for ${label}`,
        panelID: label
      }
    });
    setTabs(newTabs);

    fetchOrders();
  }, []);

  // useEffect(() => {
  //   console.log({ orders, tabs, selected });
  // }, [orders, tabs, selected]);

  useEffect(() => {
    if (!loading && !loaded) {
      fetchOrders();
    }
  }, []);

  // Fetch orders by service date
  const fetchOrders = () => {
    setLoading(true);
    fetchData(`/shopify/v1/shops/${shop.id}/orders?by_date=true`,
      (data) => {
        const orderState = data.reduce((result, order) => {
          let date = order.service.date;
          if (date === '0') {
            const dateNote = order.note_attributes.filter(n => n.name === 'Date')[0];
            if (dateNote) {
              date = dateNote.value;
            }
          }
          return result[date]
            ? { ...result, [date]: [...result[date], order] }
            : { ...result, [date]: [order] }
        }, {})
        setOrders(orderState);
        setTabs(state => state.map(tab => ({ ...tab, content: <span>{tab.content} <Badge status="new">{orderState[tab.date] ? orderState[tab.date].length : 0}</Badge></span> })));
        setLoaded(true);
      },
      (err) => console.log({ err }),
      () => setLoading(false)
    );
  }

  const handleTabChange = (selectedTabIndex) => setSelected(selectedTabIndex);

  const headers = [
    { type: 'text', label: 'Order #', fn: (order) => (
      <Button size="slim" plain onClick={() => console.log(order)} color="transparent">
        {order.name}
      </Button>
    )},
    { type: 'text', label: 'Ready By', fn: (x) => <OrderCollectionDate order={x} /> },
    { type: 'text', label: 'Customer', fn: (x) => x.customer ? `${x.customer.first_name || ''} ${x.customer.last_name}` : ''},
    { type: 'numeric', label: 'Type', fn: (x) => {
      if (x.service) {
        return x.service ? x.service.type : 'NA'
      }

      if (x.shipping_lines && x.shipping_lines.source === 'shopify') {
        return 'Pickup'
      }

      return 'Delivery';
    }, sortable: true },
    { type: 'numeric', col: null, label: '', fn: (order) => '' }
  ];

  const viewedOrders = tabs[selected] && tabs[selected].date && orders[tabs[selected].date] || [];

  return (
    <AppProvider>
      <Page title="Orders">
        <Layout>
          <Layout.Section>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <Card sectioned>
                { loading ?
                  <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                  </TextContainer>
                  :
                  viewedOrders.length > 0 ?
                    <DataTable
                      columnContentTypes={headers.map(h => h.type)}
                      headings={headers.map(h => h.label)}
                      rows={viewedOrders.map(order => headers.map(header => header.fn(header.col ? order[header.col] : order)))}
                    />
                    : <TextContainer>No orders for this date</TextContainer>
                }
              </Card>
            </Tabs>
          </Layout.Section>
        </Layout>
      </Page>
    </AppProvider>
  );
}
