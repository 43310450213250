import React from 'react'
import { Frame, Toast } from '@shopify/polaris'
import { useAdmin } from './admin';

export default function AppContainer({ children }) {
  const { toast, setToast } = useAdmin();

  const toastMarkup = toast ? (
    <Toast content={toast} onDismiss={() => setToast()} />
  ) : null;

  return (
    <Frame>
      {toastMarkup}
      {children}
      <div style={{height: '5rem'}}></div>
    </Frame>
  )
}
