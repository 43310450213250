import React, { useEffect, useState } from 'react';
import { AppProvider, Badge, Button, Card, DataTable, Layout, Page, SkeletonBodyText, SkeletonDisplayText, Tabs, TextContainer } from '@shopify/polaris';
import { getWeekday } from '../../../util/helpers';
import { fetchData } from '../../../util/fetch';
import DeliveryReadyBy from './DeliveryReadyBy';
import { useAdmin } from '../admin';

export default function Deliveries() {
  const { shop, deliveries, setDeliveries } = useAdmin();
  const [selected, setSelected] = useState(0);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState({});
  const [tabs, setTabs] = useState([]);

  // Generate tabs using the following 7 days
  useEffect(() => {
    console.log({ shop });
    const today = new Date();
    const week = [];
    const dayLabels = ['today', 'tomorrow'];

    for (let i = 0; i < 7; i++) {
      const d = new Date();
      d.setDate(today.getDate() + i);
      week.push(d);
    }

    const newTabs = week.map((date, index) => {
      const label = dayLabels[index] || getWeekday(date.getDay());

      return {
        id: label,
        date: date.toLocaleDateString('en-ca'),
        content: label,
        title: `Order for ${label}`,
        panelID: label
      }
    });
    setTabs(newTabs);

    fetchDeliveries();
  }, []);

  // useEffect(() => {
  //   console.log({ deliveries, tabs, selected });
  // }, [deliveries, tabs, selected]);

  useEffect(() => {
    if (!loading && !loaded) {
      fetchDeliveries();
    }
  }, []);

  // Fetch deliveries and group by due_by date
  const fetchDeliveries = () => {
    setLoading(true);
    fetchData(`/shopify/v1/shops/${shop.id}/deliveries?by_date=true`,
      (data) => {
        const deliveryState = data.reduce((result, delivery) => {
          const date = new Date(delivery.due_by).toLocaleDateString('en-ca');
          return result[date]
            ? { ...result, [date]: [...result[date], delivery] }
            : { ...result, [date]: [delivery] }
        }, {})
        setDeliveries(deliveryState);
        setTabs(state => state.map(tab => ({ ...tab, content: <span>{tab.content} <Badge status="new">{deliveryState[tab.date] ? deliveryState[tab.date].length : 0}</Badge></span>})));
        setLoaded(true);
      },
      (err) => {
        console.log({ err });
        setError(err);
      },
      () => setLoading(false)
    );
  }

  const navigateExternal = url => window.open(url, '_blank') || window.location.replace(url);
  const handleTabChange = (selectedTabIndex) => setSelected(selectedTabIndex);

  const headers = [
    { type: 'text', label: 'Status', fn: (d) => d.status },
    { type: 'text', label: 'Provider', fn: (delivery) => delivery.shipping_provider },
    { type: 'text', label: 'Customer', fn: (d) => d.receiver && d.receiver.name },
    { type: 'text', label: 'Destination', fn: (d) => d.destination && (d.destination.street || d.destination.address1) },
    { type: 'text', label: 'Ready By', fn: (delivery) => <DeliveryReadyBy delivery={delivery} /> },
    { type: 'text', label: '', fn: (d) => d.shipping_label ? <Button onClick={() => navigateExternal(d.shipping_label)}>Label</Button> : '' },
  ];

  const viewedDeliveries = tabs[selected] && tabs[selected].date && deliveries[tabs[selected].date] || [];

  return (
    <AppProvider>
      <Page title="Deliveries">
        <Layout>
          <Layout.Section>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <Card sectioned>
                { loading ?
                  <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                  </TextContainer>
                  :
                  viewedDeliveries.length > 0 ?
                    <DataTable
                      columnContentTypes={headers.map(h => h.type)}
                      headings={headers.map(h => h.label)}
                      rows={viewedDeliveries.map(delivery => headers.map(header => header.fn(header.col ? delivery[header.col] : delivery)))}
                    />
                    : <TextContainer>No deliveries for this date</TextContainer>
                }
              </Card>
            </Tabs>
          </Layout.Section>
        </Layout>
      </Page>
    </AppProvider>
  );
}
