import React from 'react';
import { Redirect } from '@shopify/app-bridge/actions';
import { Layout, Page } from '@shopify/polaris';

export default function Dashboard() {
  // const CARRIER_NAME = 'Grocer Box';
  // const { shop, webhooks, carriers } = useAdmin();

  // useEffect(() => {
  //   console.log({ shop, webhooks, carriers });
  // }, []);

  // const grocerBoxCarrier = carriers.filter(c => c.name === CARRIER_NAME)[0];
  // { grocerBoxCarrier ? 'Carrier Service is enabled' : 'Carrier service is not enabled' }
  return (
    <Page title="Grocer Box">
      <Layout>
        <Layout.Section>
          <Redirect to="/shopify/orders" />
        </Layout.Section>
      </Layout>
    </Page>
  )
}
