import React, { useEffect, useState } from 'react';

import { Card, DataTable, Layout, TextField } from '@shopify/polaris';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
// import CustomDropdown from 'components/CustomDropdown/CustomDropdown';

export default function WeeklySchedule({ schedule, handleSave }) {
  const [form, setForm] = useState(schedule);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setForm(schedule);
  }, [schedule])

  const saveSchedule = (sched) => {
    setSaving(true);
    handleSave(sched).finally(() => setSaving(false));
  }

  const headings = ['Open', 'Day', 'Windows', 'Limit Per Window']
  const rows = Object.keys(form).map(wkday => {
    const day = form[wkday];
    return [
      <Checkbox
        disabled={saving}
        checked={day.closed}
        tabIndex={-1}
        checkedIcon={<CloseIcon />}
        icon={<CheckIcon  />}
        onClick={() => saveSchedule({ ...schedule, [wkday]: { ...day, closed: !day.closed }})}
      />,
      day.label,
      day.closed ? 'Shop is closed.' :
      <div>
        {day.windows.map(window =>
          <span key={window.id}>
            <Checkbox
              disabled={saving}
              checked={window.active}
              onChange={() => saveSchedule({ ...schedule, [wkday]: { ...day, windows: day.windows.map(w => w.id === window.id ? { ...window, active: !window.active } : w) }})}
              name={window.id}
              color="primary"
            />
            {' ' + window.name}
          </span>
        )}
      </div>,
      <TextField
        type="text"
        step={5}
        min={0}
        value={`${day ? day.windows[0].limit : 0}`}
        onBlur={() => saveSchedule(form)}
        onChange={(val) => {
          if (!isNaN(val)) {
            setForm({ ...form, [wkday]: { ...day, windows: day.windows.map(w => ({ ...w, limit: +val })) }})
          }
        }}
      />
    ]
  });

  return (
    <Layout.AnnotatedSection
      title="Weekly Schedule"
      description="Customize store pick up and delivery windows. Close store on certain days or disable specific windows to suit your needs.">
      <Card>
        <DataTable
          columnContentTypes={['text', 'text', 'text']}
          headings={headings}
          rows={rows}
        />
      </Card>
    </Layout.AnnotatedSection>
  );
}
