import React from 'react'
import { orderNote } from '../../../util/helpers';
// import { parseOrderData } from '../../../util/helpers';

// Some older orders will only have service objects,
// newer orderes will have data stored in the note_attributes
export default function OrderCollectionDate({ order }) {
  let date = orderNote(order, 'Date') || order.service.date;
  let window = orderNote(order, 'window') || order.service.window;
  if (window) { window = window.split(' - ')[0];}

  return (
    <span>{date} - {window}</span>
  )

  // const { collectionDate, pickupTime } = parseOrderData(order);

  // if (!collectionDate) {
  //   return '';
  // }

  // const time = pickupTime || collectionDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  // return (
  //   <span>{time} - {collectionDate.toLocaleDateString()}</span>
  // )
}
