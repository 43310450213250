import React from 'react'

export default function DeliveryReadyBy({ delivery }) {
  if (!delivery.data || !delivery.data.ready_by) {
    return ''
  }

  const readyBy = new Date(delivery.data.ready_by)
  return (
    <span>{readyBy.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {readyBy.toLocaleDateString()}</span>
  )
}
