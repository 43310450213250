import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom'
import { useRoutePropagation } from '@shopify/app-bridge-react';
import Orders from './Orders/Orders';
// // import Dashboard from './Dashboard/Dashboard';
import Settings from './Settings/Settings';
// import Deliveries from './Deliveries/Deliveries';
import Shopper from './Shopper/Shopper';

function Routes(props) {
  const { location } = props;

  useRoutePropagation(location);

  // <Route exact path="/shopify/deliveries" component={() => <Deliveries />} />
  // <Route exact path="/shopify" component={Shopper} />
  return (
    <Switch>
      <Route exact path="/shopify/orders" component={() => <Orders />} />
      <Route exact path="/shopify/settings" component={() => <Settings />} />
    </Switch>
  );
}

export default withRouter(Routes);
