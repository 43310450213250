import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { postData, putData, deleteRequest } from '../../util/fetch';

const AdminContext = React.createContext();

const AdminProvider = (props) => {
  const [shop, setShop] = useState(props.shop);
  const [carriers] = useState(props.carriers);
  const [webhooks] = useState(props.webhooks);
  const [orders, setOrders] = useState({}); // { [date: string]: order[] }
  const [deliveries, setDeliveries] = useState({}); // { [date: string]: delivery[] }
  const [toast, setToast] = useState();
  const history = useHistory();

  const navigateExternal = url => window.open(url, '_blank') || window.location.replace(url);

  const navigateToShopify = (path) => {
    const url = `//${selectedShop.shopify_domain}/${path}`;
    navigateExternal(url);
  };

  const saveDeliveryRate = (deliveryRate) => {
    return postData(`/shopify/v1/shops/${shop.id}/shop_config/delivery_rates`, { delivery_rate: deliveryRate })
      .then(res => res.json())
      .then(delivery_rates => {
        setShop({
          ...shop,
          shop_config: {
            ...shop.shop_config,
            delivery_rates
          }
        });
        setToast('Delivery rate saved.')
      })
  }

  const deleteDeliveryRate = (deliveryRate) => {
    return deleteRequest(`/shopify/v1/shops/${shop.id}/shop_config/delivery_rates/${encodeURIComponent(deliveryRate.name)}`)
      .then(res => res.json())
      .then(() => {
        setShop({
          ...shop,
          shop_config: {
            ...shop.shop_config,
            delivery_rates: shop.shop_config.delivery_rates.filter(d => d.name !== deliveryRate.name)
          }
        });
        setToast('Delivery rate deleted.')
      })
  }

  const saveAvailabilityRule = (availability_rule) => {
    return postData(`/shopify/v1/shops/${shop.id}/shop_config/availability_rules`, { availability_rule })
      .then(res => res.json())
      .then(availability_rules => {
        setShop({
          ...shop,
          shop_config: {
            ...shop.shop_config,
            availability_rules
          }
        });
        setToast('Availability rule saved.')
      })
  }

  const deleteAvailabilityRule = (type, value) => {
    return deleteRequest(`/shopify/v1/shops/${shop.id}/shop_config/availability_rules/${encodeURIComponent(type)}/${encodeURIComponent(value)}`)
      .then(res => res.json())
      .then((availability_rules) => {
        setShop({
          ...shop,
          shop_config: {
            ...shop.shop_config,
            availability_rules
          }
        });
        setToast('Availability rule deleted.')
      })
  }

  const saveDeliveryDiscount = (delivery_discount) => {
    return postData(`/shopify/v1/shops/${shop.id}/shop_config/delivery_discounts`, { delivery_discount })
      .then(res => res.json())
      .then(delivery_discounts => {
        setShop({
          ...shop,
          shop_config: {
            ...shop.shop_config,
            delivery_discounts
          }
        });
        setToast('Delivery discount saved.')
      })
  }

  const deleteDeliveryDiscount = (name) => {
    return deleteRequest(`/shopify/v1/shops/${shop.id}/shop_config/delivery_discounts/${encodeURIComponent(name)}`)
      .then(res => res.json())
      .then((delivery_discounts) => {
        setShop({
          ...shop,
          shop_config: {
            ...shop.shop_config,
            delivery_discounts
          }
        });
        setToast('Delivery discount deleted.')
      })
  }

  const saveCalendar = (calendar) => {
    return postData(`/shopify/v1/shops/${shop.id}/shop_config/calendar`, { calendar })
      .then(res => res.json())
      .then(calendar => {
        setShop({
          ...shop,
          shop_config: {
            ...shop.shop_config,
            calendar
          }
        });
        setToast('Calendar saved.')
      })
      .catch(() => {
        setToast('Error saving calendar.')
      })
  }

  const saveSchedule = (schedule) => {
    return postData(`/shopify/v1/shops/${shop.id}/shop_config/schedule`, { schedule })
      .then(res => res.json())
      .then(schedule => {
        setShop({
          ...shop,
          shop_config: {
            ...shop.shop_config,
            schedule
          }
        });
        setToast('Schedule saved.')
      })
      .catch(() => {
        setToast('Error saving schedule.')
      });
  }

  const updateShopConfig = (data) => {
    return putData(`/shopify/v1/shops/${shop.id}/shop_config`, { shop_config: data })
      .then(res => res.json())
      .then((shop_config) => {
        setShop({ ...shop, shop_config });
        setToast('Setting updated.');
        return shop_config;
      });
  }

  const downloadFromLink = (link, filename = null) => {
    fetch(link)
      .then(res => res.blob())
      .then(blob => {
        window.download(blob, filename);
      });
  }

  return <AdminContext.Provider value={{
    shop,
    carriers,
    webhooks,
    orders,
    setOrders,
    deliveries,
    setDeliveries,
    navigateExternal,
    navigateToShopify,
    saveDeliveryRate,
    deleteDeliveryRate,
    saveAvailabilityRule,
    deleteAvailabilityRule,
    saveDeliveryDiscount,
    deleteDeliveryDiscount,
    updateShopConfig,
    saveCalendar,
    saveSchedule,
    toast,
    setToast,
    downloadFromLink
  }} {...props} />
}

const useAdmin = () => React.useContext(AdminContext)

export {AdminProvider, useAdmin}