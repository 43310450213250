import React, { useState } from 'react'
import { Layout, Card, FormLayout, TextField, TextContainer, Modal, Popover, ActionList, DataTable } from '@shopify/polaris'
import { MoreHoriz as MoreIcon } from '@material-ui/icons';

export default function DeliverySettings({ shop, saveDeliveryRate, deleteDeliveryRate }) {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [ratePopover, setRatePopover] = useState(false);

  const handleChange = (key, val) => {
    setEditing({
      ...editing,
      [key]: val
    });
  }

  const handleEdit = (deliveryRate) => {
    setEditing(deliveryRate);
    setRatePopover(false);
  }

  const handleSave = (deliveryRate) => {
    setSaving(true);
    saveDeliveryRate(deliveryRate).then(() => setSaving(false))
    setEditing(false);
    setRatePopover(false);
  }

  const handleRemove = (deliveryRate) => {
    setSaving(true);
    deleteDeliveryRate(deliveryRate).then(() => setSaving(false))
  }

  const handleAddDeliveryRate = () => {
    setEditing({ name: '', radius: 0, price_cents: 0})
  }

  const headers = [
    { type: 'text', label: 'Name', col: 'name', fn: (x) => x },
    { type: 'text', label: 'Radius', col: 'radius', fn: (x) => x},
    { type: 'numeric', label: 'Price', col: 'price_cents', fn: (x) => `$${x / 100.0}` },
    { type: 'numeric', col: null, label: '', fn: (deliveryRate) => {
      return <Popover
        active={ratePopover === deliveryRate.name}
        activator={<MoreIcon style={{cursor: 'pointer'}} onClick={() => setRatePopover(deliveryRate.name)} />}
        onClose={() => setRatePopover(false)}
      >
        <ActionList items={[
          {content: 'Edit', onAction: () => handleEdit(deliveryRate)},
          {content: 'Delete', onAction: () => handleRemove(deliveryRate), destructive: true }
        ]} />
      </Popover>
    }}
  ];

  const deliveryRates = shop.shop_config.delivery_rates.sort((a, b) => a.radius - b.radius);

  return (
    <Layout.AnnotatedSection
      title="Store Powered Delivery"
      description="Deliver to customers yourself."
    >
      <Card>
        <Card.Header
          actions={[
            { content: 'Add', onAction: handleAddDeliveryRate },
          ]}
          title="Delivery Rates"
        >
        </Card.Header>
        { deliveryRates.length > 0 ?
          <DataTable
            columnContentTypes={headers.map(h => h.type)}
            headings={headers.map(h => h.label)}
            rows={deliveryRates.map(order => headers.map(header => header.fn(header.col ? order[header.col] : order)))}
          />
          :
          <Card.Section>
            <TextContainer>
              Add delivery rates with prices based on distance from your store.
            </TextContainer>
          </Card.Section>
        }
      </Card>

      <Modal
        title="Delivery Rate"
        open={editing}
        onClose={() => setEditing(false)}
        secondaryActions={[{ content: 'Cancel', onAction: () => setEditing(false) }]}
        primaryAction={{content: 'Save', loading: saving, onAction: () => handleSave(editing), disabled: saving}}>
        <Modal.Section>
          <FormLayout>
            <TextField
              label="Name"
              type="text"
              min={0}
              value={`${editing.name}`}
              onChange={(val) => handleChange('name', val)}
            />

            <TextField
              label="Radius (km)"
              type="number"
              min={0}
              value={`${editing.radius}`}
              onChange={(val) => handleChange('radius', +val)}
            />

            <TextField
              label="Price ($)"
              type="number"
              min={0}
              value={`${editing.price_cents / 100.0}`}
              onChange={(val) => handleChange('price_cents', (+val * 100))}
            />
          </FormLayout>
        </Modal.Section>
      </Modal>
    </Layout.AnnotatedSection>
  )
}
