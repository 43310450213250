import React, { useState, useEffect } from 'react'
import { AppProvider, Button, Card, DataTable, Layout, Page, SkeletonBodyText, SkeletonDisplayText, TextContainer } from '@shopify/polaris';
import { useAdmin } from '../admin';
import { fetchData } from '../../../util/fetch';
import CurrentOrder from './CurrentOrder';

function Shopper() {
  const { shop } = useAdmin();
  const [orders, setOrders] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (!loading && !loaded) {
      fetchOrders();
      console.log({ here: "HERE"})
    }
  }, []);

  // Fetch orders by service date
  const fetchOrders = () => {
    setLoading(true);
    fetchData(`/shopify/v1/shops/${shop.id}/orders?by_date=true`,
      (data) => {
        console.log({ data });
        setOrders(data);
        setLoaded(true);
      },
      (err) => console.log({ err }),
      () => setLoading(false)
    );
  }

  const headers = [
    { type: 'text', label: 'Order #', fn: (order) => (
      <Button size="slim" plain onClick={() => console.log(order)} color="transparent">
        {order.name}
      </Button>
    )},
    { type: 'text', label: 'Customer', fn: (x) => x.customer ? `${x.customer.first_name || ''} ${x.customer.last_name}` : ''},
    { type: 'numeric', col: null, label: '', fn: (order) => '' },
    { type: 'text', label: '', fn: (order) => (
      <Button size="slim" plain onClick={() => setSelected(order)} color="transparent">
        Shop
      </Button>
    )},
  ];


  return (
    <AppProvider>
      <Page title="Orders">
        <Layout>
          { selected ?
            <CurrentOrder order={selected} />
          : ''}
          <Layout.Section>
            <Card sectioned>
              { loading ?
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText />
                </TextContainer>
                :
                orders.length > 0 ?
                  <DataTable
                    columnContentTypes={headers.map(h => h.type)}
                    headings={headers.map(h => h.label)}
                    rows={orders.map(order => headers.map(header => header.fn(header.col ? order[header.col] : order)))}
                  />
                  : <TextContainer>No orders for this date</TextContainer>
              }
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </AppProvider>
  )
}

export default Shopper
